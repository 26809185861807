<template>
  <div>
    <el-dialog top="5vh"
               title="生成课酬单"
               :visible.sync="dialogFormVisible"
               @close="emitClose"
               :close-on-click-modal="false"
               width="1080px">
      <el-form :model="dataForm"
               label-position="left"
               ref="formRules"
               :rules="rules"
               label-width="90px"
               size="small">
        <el-form-item label="授课老师：">
          <div class="info">
            {{teacher_info.realname}}/{{teacher_info.mobile}}
          </div>
        </el-form-item>
        <el-form-item label="账单名称：">
          <div class="form-item">
            <el-input v-model="dataForm.order_name"
                      placeholder="请输入账单名称"></el-input>
            <div class="amount-item">
              <div class="item-label">基础课时费金额</div>
              <div class="item-value">{{totalAmount}}元</div>
            </div>
            <div class="amount-item">
              <div class="item-label">大写金额</div>
              <div class="item-value">{{formateTotalAmount(totalAmount)}}</div>
            </div>
            <div class="amount-item">
              <div class="item-label">总计时长</div>
              <div class="item-value">{{totalHour}}小时</div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input type="textarea"
                    v-model="dataForm.remark"
                    placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <div class="table-modular">
        <div class="header">
          已选课时
        </div>
        <el-table :data="result"
                  :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                  :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                  border>
          <!-- @selection-change="handleSelectionChange"
          <el-table-column
            type="selection"
            width="50"
            fixed="left"
          >
          </el-table-column> -->

          <el-table-column prop="dispatch_log_course_theme"
                           label="课程主题"
                           width="200"
                           show-overflow-tooltip>
          </el-table-column>

          <el-table-column label="课程时长"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{scope.row.final_real_lesson_hour}}小时
            </template>
          </el-table-column>
          <el-table-column label="基础课时费"
                           width="150"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.final_real_salary_split_type==1"> {{scope.row.final_real_lesson_hour_amount}}元/小时</span>
              <span v-if="scope.row.final_real_salary_split_type==2"> {{scope.row.final_real_salary_profit_fix_amount? scope.row.final_real_salary_profit_fix_amount :0}}元/小时</span>

            </template>
            <!-- <template slot-scope="scope">
              {{scope.row.final_real_salary_profit_fix_amount? scope.row.final_real_salary_profit_fix_amount :0}}元/小时
            </template> -->
          </el-table-column>
          <!-- <el-table-column label="课时费"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{scope.row.final_real_lesson_hour_amount}}元/小时
            </template>
          </el-table-column> -->
          <el-table-column label="课时状态"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag v-if="scope.row.lesson_status==0"
                      size="mini"
                      type="warning">待确认</el-tag>
              <el-tag v-if="scope.row.lesson_status==1"
                      size="mini">待回访</el-tag>
              <el-tag v-if="scope.row.lesson_status==2"
                      size="mini"
                      type="danger">已拒绝</el-tag>
              <el-tag v-if="scope.row.lesson_status==3"
                      size="mini"
                      type="success">已完成</el-tag>
              <!--     <el-tag
                v-if="scope.row.lesson_status==4"
                size="mini"
                type="info"
              >已取消</el-tag> -->
              <el-tag v-if="scope.row.lesson_status==5"
                      size="mini"
                      type="info">已完结</el-tag>
              <el-tag v-if="scope.row.lesson_status==6"
                      size="mini"
                      type="info">已暂停</el-tag>
            </template>
          </el-table-column>

          <!-- <el-table-column prop="name"
                           label="操作"
                           width="100"
                           fixed="right">
            <template slot-scope="scope">
              <div class="templateLink">
                <el-link type="primary"
                         :underline="false"
                         @click="handleToEdit(scope.row)">修改</el-link>
              </div>
            </template>
          </el-table-column> -->
        </el-table>
        <!-- <div class="btn-wrap">
          <el-button
            size="mini"
            @click="handleToEditList"
          >修改课时费</el-button>
        </div> -->
      </div>

      <div class="line"></div>

      <div class="table-modular">
        <div class="header">
          对应该老师其他已完成且待生成课酬单的课时记录
        </div>
        <el-table :data="result_other"
                  :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                  :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                  border>

          <el-table-column prop="dispatch_log_course_theme"
                           label="课程主题"
                           show-overflow-tooltip>
          </el-table-column>
          <!-- <el-table-column
            prop="plan_course_content"
            label="课程内容"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column> -->
          <el-table-column label="课程时长"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{scope.row.final_real_lesson_hour}}小时
            </template>
          </el-table-column>
          <el-table-column label="基础课时费"
                           width="150"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.final_real_salary_split_type==1"> {{scope.row.final_real_lesson_hour_amount}}元/小时</span>
              <span v-if="scope.row.final_real_salary_split_type==2"> {{scope.row.final_real_salary_profit_fix_amount? scope.row.final_real_salary_profit_fix_amount :0}}元/小时</span>

            </template>
          </el-table-column>
          <el-table-column label="课时状态"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag v-if="scope.row.lesson_status==0"
                      size="mini"
                      type="warning">待确认</el-tag>
              <el-tag v-if="scope.row.lesson_status==1"
                      size="mini">待回访</el-tag>
              <el-tag v-if="scope.row.lesson_status==2"
                      size="mini"
                      type="danger">已拒绝</el-tag>
              <el-tag v-if="scope.row.lesson_status==3"
                      size="mini"
                      type="success">已完成</el-tag>
              <!--     <el-tag
                v-if="scope.row.lesson_status==4"
                size="mini"
                type="info"
              >已取消</el-tag> -->
              <el-tag v-if="scope.row.lesson_status==5"
                      size="mini"
                      type="info">已完结</el-tag>
              <el-tag v-if="scope.row.lesson_status==6"
                      size="mini"
                      type="info">已暂停</el-tag>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="grade_name"
            label="所在班级"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column> -->
          <el-table-column prop="name"
                           label="操作"
                           width="100"
                           fixed="right">
            <template slot-scope="scope">
              <div class="templateLink">
                <el-link type="primary"
                         :underline="false"
                         @click="handleToChoose(scope.row)">选择</el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page-footer">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>

      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button type="primary"
                   @click="submit('formRules')">确定</el-button>
      </div>
    </el-dialog>
    <EditLogHourAmount :content="editContent"
                       :isVisible="editDialog"
                       @close="editDialog=false"
                       @submit="EditLogHourAmountSubmit" />
    <EditLogHourAmountList :list="editlist"
                           :isVisible="editlistDialog"
                           @close="editlistDialog=false"
                           @submit="EditLogHourAmountListSubmit" />
  </div>
</template>

<script>
import { teacherOne, lessonLoglist } from '@/request/api'
import { adminUrl } from '@/utils/helper.js'
import { digitUppercase } from '@/utils/helper'
import EditLogHourAmount from '@/components/DialogComponents/Classregistration/EditLogHourAmount.vue'
import EditLogHourAmountList from '@/components/DialogComponents/ClassService/EditLogHourAmountList.vue'

export default {
  components: { EditLogHourAmount, EditLogHourAmountList },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {},
      rules: {},
      teacher_info: {},
      result: [],
      chooseList: [],
      totalAmount: 0,
      totalHour: 0,
      editDialog: false,
      editContent: {},
      editlist: [],
      editlistDialog: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 0,
      result_other: [],
      unitConversion: 1,
    }
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
      console.log(val)
      if (val) {
        this.fetTeacherDetail()
      }
    },
    result(val) {},
  },
  methods: {
    emitClose() {
      this.result_other = []
      this.result = []
      this.dataForm = {}
      this.$emit('close')
    },
    fetTeacherDetail() {
      teacherOne({ id: this.content[0].teacher_id }).then((response) => {
        let res = response.data
        this.teacher_info = res.data
        let mouth = this.$moment()
          .month(this.$moment().month() - 1)
          .startOf('month')
          .format('MM')
        this.$set(
          this.dataForm,
          'order_name',
          `${this.teacher_info.realname}的${mouth}月派遣课酬`
        )
        this.result = JSON.parse(JSON.stringify(this.content))
        this.computedAmount()
        this.fetList()
      })
    },
    fetList() {
      const params = Object.assign({}, this.query, {
        teacher_id: this.result[0].teacher_id,
        lesson_statuses: 3,
        grade_id: this.result[0].grade_id,
        force_delete_types: 2,
      })
      lessonLoglist(params).then((response) => {
        let res = response.data
        this.pageTotal = res.data.count
        this.filterList(res.data.list)
      })
    },
    filterList(list) {
      let arr = list.filter((v) => {
        return (
          this.result.findIndex((t) => {
            return t.id == v.id
          }) == -1
        )
      })
      this.result_other = arr
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let a = this.result.reduce((acc, cur) => {
            return (
              acc +
              Number(
                cur.final_real_lesson_hour_amount * cur.final_real_lesson_hour
              )
            )
          }, 0)
          let params = {
            senior_id: this.result[0].teacher_id,
            order_name: this.dataForm.order_name,
            total_amount: Math.round(a * 100) / 100,
            total_lesson_hour: Number(this.totalHour),
            arr_lesson_log: this.result.map((v) => {
              return {
                log_id: v.id,
                final_real_lesson_hour: v.final_real_lesson_hour,
                final_real_lesson_hour_amount: v.final_real_lesson_hour_amount,
                final_real_salary_split_type: v.final_real_salary_split_type,
                final_real_salary_profit_fix_amount:
                  v.final_real_salary_profit_fix_amount,
                final_real_bonus_profit_fix_amount:
                  v.final_real_bonus_profit_fix_amount,
              }
            }),
          }
          let str = adminUrl()
          this.$axios({
            url: `${str}/api/v1/back/senior/course/order`,
            method: 'post', //可以省略 不写默认是get
            data: params,
          }).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.$message.success('创建成功')
              this.$emit('submit')
              this.emitClose()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    computedAmount() {
      console.log('this.result', this.result)
      let a = this.result.reduce((acc, cur) => {
        if (cur.final_real_salary_split_type == 2) {
          return (
            acc +
            Number(
              cur.final_real_salary_profit_fix_amount *
                cur.final_real_lesson_hour
            )
          )
        } else {
          return (
            acc +
            Number(
              cur.final_real_lesson_hour_amount * cur.final_real_lesson_hour
            )
          )
        }
      }, 0)
      let b = this.result.reduce((acc, cur) => {
        return acc + Number(cur.final_real_lesson_hour)
      }, 0)
      this.totalAmount = Math.round(a * 100) / 100
      this.totalHour = b.toFixed(2)

      // let a = this.result.reduce((acc, cur) => {
      //   return (
      //     acc +
      //     Number(cur.final_real_lesson_hour_amount * cur.final_real_lesson_hour)
      //   )
      // }, 0)
      // let b = this.result.reduce((acc, cur) => {
      //   return acc + Number(cur.final_real_lesson_hour)
      // }, 0)
      // this.totalAmount = a.toFixed(2)
      // this.totalHour = b.toFixed(3)
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    formateTotalAmount(e) {
      return digitUppercase(e)
    },
    handleToEdit(row) {
      this.editContent = Object.assign({}, row)
      this.editDialog = true
    },
    EditLogHourAmountSubmit(data) {
      let ind = this.result.findIndex((v) => {
        return v.id == data.id
      })
      this.$set(
        this.result[ind],
        'final_real_lesson_hour_amount',
        data.final_real_lesson_hour_amount
      )
      this.computedAmount()
      this.$emit('submit')
    },
    EditLogHourAmountListSubmit(data) {
      data.ids.forEach((item, index) => {
        this.$set(
          this.result.find((v) => {
            return v.id == item
          }),
          'final_real_lesson_hour_amount',
          data.final_real_lesson_hour_amount
        )
      })
      this.computedAmount()
    },
    handleToEditList() {
      this.editlist = this.chooseList
      this.editlistDialog = true
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.getList()
    },
    handleToChoose(row) {
      let obj = Object.assign({}, row)
      this.result.push(obj)
      this.filterList(this.result_other)
      this.computedAmount()
    },
    handleCommand(e) {
      this.unitConversion = e
      this.$refs['popover'].doClose()
    },
  },
}
</script>

<style lang="less" scoped>
.info {
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #303133;
}
.form-item {
  display: flex;
  .el-input {
    width: 300px;
  }
  .amount-item {
    display: flex;
    align-items: center;
    margin-left: 16px;
    .item-label {
      font-size: 14px;
      font-weight: 400;
      color: #909399;
    }
    .item-value {
      font-size: 14px;
      margin-left: 16px;
      font-weight: 600;
      color: #e6a23c;
    }
  }
}
.table-modular {
  margin-top: 24px;
  .header {
    font-size: 14px;
    font-weight: 400;
    color: #303133;
    margin-bottom: 16px;
  }
}
.btn-wrap {
  margin-top: 16px;
}
.line {
  border: dashed 1px #dcdfe6;
  margin: 32px 0px;
}
.page-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 16px 0px;
}
</style>