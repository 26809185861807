<template>
  <div class="content">
    <el-dialog top="5vh"
               title="学员信息"
               :visible.sync="dialogFormVisible"
               @close="emitClose"
               width="570px">
      <el-form :model="dataForm"
               label-position="right"
               ref="formRules"
               :rules="rules"
               label-width="110px"
               size="small">
        <el-form-item label="姓名/昵称："
                      prop="realname">
          <el-input v-model="dataForm.realname"
                    autocomplete="off"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="联系方式：">
          <div style="display:flex; justify-content: space-between;">
            <el-input placeholder=""
                      :style="{width:dataForm.phone?'100%':'80%'}"
                      v-model.trim="dataForm.phone"
                      class="input-with-select">
              <template slot="prepend">手机号</template>
            </el-input>
            <div class="randoms"
                 @click="handleRandow"
                 v-if="dataForm.phone==''">随机生成手机号</div>
            <div class="random"
                 v-if="dataForm.phone !=''&& dataForm.phone.startsWith(11)"
                 @click="handleCopy"
                 :data-clipboard-text="clipboardPhone">复制</div>
          </div>
          <el-input placeholder=""
                    v-model="dataForm.wechat"
                    class="input-with-select">
            <template slot="prepend">微信号</template>
          </el-input>
          <el-input placeholder=""
                    v-model="dataForm.qq"
                    class="input-with-select">
            <template slot="prepend">&nbsp;QQ号</template>
          </el-input>
        </el-form-item>
      </el-form>

      <div slot="footer"
           class="dialog-footer">
        <div> <el-button type="primary"
                     class="type"
                     :data-clipboard-text="clipboardText"
                     @click="copy">复制学员信息</el-button>
          <el-button type="primary"
                     @click="handleToChangeStatus(dataForm)"
                     v-if="dataForm.docking_status == 0">已对接</el-button>
        </div>

        <el-button type="primary"
                   @click="submit('formRules')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { editstudent, member } from '@/request/api'
import Clipboard from 'clipboard'
import { adminUrl } from '@/utils/helper.js'
export default {
  components: {},
  props: {
    content: {
      type: Object,
      default: () => {
        return null
      },
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      clipboardText: '',
      clipboardPhone: '',
      dataForm: {},
      rules: {
        realname: [
          { required: true, message: '请输入学员姓名', trigger: 'blur' },
        ],
      },
    }
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
      if (val) {
        this.dataForm = this.content
        console.log(this.dataForm)
      }
    },
  },
  created() {},
  computed: {},
  methods: {
    handleRandow() {
      const prefix = '11' // 前两位固定
      const userId = String(this.content.id) // 用户 ID
      // 计算中间随机位数
      const randomLength = 11 - prefix.length - userId.length
      // 随机生成中间部分
      const middle = Array.from({ length: randomLength }, () =>
        Math.floor(Math.random() * 10)
      ).join('')

      // 拼接完整手机号
      this.dataForm.phone = `${prefix}${middle}${userId}`
      console.log('密码', this.dataForm.phone.slice(-6))
      setTimeout(() => {
        let params = {
          mobile: this.dataForm.phone,
          nickname: this.dataForm.realname,
          password: '112233',
          realname: this.dataForm.realname,
          status: 1,
        }
        let str = adminUrl()
        this.$axios({
          url: `${str}/api/v1/back/member`,
          method: 'post', //可以省略 不写默认是get
          data: params,
        }).then((response) => {
          let res = response.data
          if (res.code == 0) {
            this.$message.success('操作成功')
          }
        })
      }, 500)
    },
    handleCopy() {
      this.clipboardPhone = `后续课后老师会在群中发送课时确认链接，点击链接后用以下账号密码登录，首次登录后会提示修改密码。
账号：${this.dataForm.phone} 密码： 112233
为方便后续课时确认，请保存好账号密码，确认有问题可以随时联系我。
      `
      console.log(' this.clipboardPhone', this.clipboardPhone)
      var clipboard = new Clipboard('.random')
      clipboard.on('success', (e) => {
        this.$message({
          message: '复制成功！',
          type: 'success',
        })
        this.handleToClose()
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({
          message: '该浏览器不支持自动复制',
          type: 'error',
        })
        // 释放内存
        clipboard.destroy()
      })
    },
    copy() {
      let subject = []
      this.dataForm.subjects.map((item) => {
        subject.push('（' + item.subject_code + '）' + item.subject_name)
      })
      let hour = (this.dataForm.service_hours / 0.75).toFixed(2)
      this.clipboardText = `先跟你核对一下信息哟~
我们会根据你核对的信息安排后续课程！
---
姓名：${this.dataForm.realname}
手机号：${this.dataForm.phone}
考研年份：${this.dataForm.apply_year}
报考学校：${this.dataForm.apply_college_name}
报考学院：${this.dataForm.apply_academy_name}
报考专业:（${this.dataForm.apply_major_code}）${
        this.dataForm.apply_major_name
      }专业
报考科目:${subject.join('+')}
---
总课时：${hour}课时，1课时45分钟，一共累计上满 ${
        this.dataForm.service_hours
      }小时 
授课形式：网授课 `
      var clipboard = new Clipboard('.type')
      clipboard.on('success', (e) => {
        this.$message({
          message: '复制成功！',
          type: 'success',
        })
        this.handleToClose()
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({
          message: '该浏览器不支持自动复制',
          type: 'error',
        })
        // 释放内存
        clipboard.destroy()
      })
    },
    handleToChangeStatus(row) {
      this.$confirm('确定已对接该学员吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let query = {
          id: row.id,
          docking_status: 1,
        }
        editstudent(query).then((res) => {
          this.emitClose()
          this.$emit('submit')
        })
      })
    },
    emitClose() {
      this.$emit('close')
    },
    submit(rules) {
      if (!/^1[123456789]\d{9}$/.test(this.dataForm.phone)) {
        this.$message({
          message: '请填写正确的手机号',
          type: 'error',
          duration: 1000,
        })
        return false
      }
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            id: this.dataForm.id,
            realname: this.dataForm.realname,
            phone: this.dataForm.phone,
            qq: this.dataForm.qq,
            wechat: this.dataForm.wechat,
          }
          editstudent(query).then((res) => {
            if (res.data.code == 0) {
              this.emitClose()
              this.$emit('submit')
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  .input-with-select {
    margin-bottom: 16px;
  }
  .random {
    cursor: pointer;
    color: #fff;
    text-align: center;
    line-height: 30px;
    width: 120px;
    height: 30px;
    background: #409eff;
  }
  .randoms {
    border-radius: 2px;
    cursor: pointer;
    color: #fff;
    text-align: center;
    line-height: 33px;
    width: 120px;
    height: 33px;
    background: #409eff;
  }
  .dialog-footer {
    display: flex;
    justify-content: space-between;
  }
}
</style>
