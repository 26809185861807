<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>学管师课程安排</el-breadcrumb-item>
        <el-breadcrumb-item>计划管理</el-breadcrumb-item>
        <el-breadcrumb-item>计划审核</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button type="primary"
                   @click="copy"
                   size="small"
                   style="margin-right: 10px">复制链接给学员</el-button>
        <div @click="copyWord"
             v-show="false"
             :data-clipboard-text="clipboardText"
             class="copy"
             ref="copys"></div>
        <!-- <el-button type="primary" @click="copyWord" :data-clipboard-text="clipboardText" class="copy" ref="copys">复制链接给学员</el-button> -->
        <el-button type="primary"
                   size="small"
                   @click="submit">提交</el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-descriptions :title="gradeDetail.name">
        <el-descriptions-item label="班级类型：">
          {{
            gradeDetail.type == 0
              ? "一对一"
              : gradeDetail.type == 1
              ? "小班课"
              : gradeDetail.type == 2
              ? "大班课"
              : "试听课"
          }}
        </el-descriptions-item>
        <el-descriptions-item label="辅导院校：">{{
          gradeDetail.apply_college_name
        }}</el-descriptions-item>
        <el-descriptions-item label="辅导专业：">{{
          gradeDetail.apply_major_name
        }}</el-descriptions-item>
        <el-descriptions-item label="辅导学院：">{{
          gradeDetail.apply_academy_name
        }}</el-descriptions-item>
        <el-descriptions-item label="辅导科目：">{{
          getSubject(gradeDetail.apply_subjects)
        }}</el-descriptions-item>
        <el-descriptions-item label="辅导课时：">
          {{ (gradeDetail.cocah_lesson_hour / rate).toFixed(2) }} 课时({{
            gradeDetail.cocah_lesson_hour
          }}小时)</el-descriptions-item>
      </el-descriptions>
    </el-card>

    <el-card style="margin-top: 20px">
      <el-tabs v-model="activeName"
               class="tabs">
        <el-tab-pane label="推荐参考书"
                     name="first">
          <div class="view"
               @click="hanledDatum"
               style="cursor: pointer">
            <div class="datum"
                 v-for="(item, index) in subject_reference_list"
                 :key="index">
              <div>专业课名称：{{ item.subject_name }}</div>
              <div v-for="(its, ins) in item.reference_list">
                参考书：{{ its.refence_name }}
              </div>
            </div>
            <div v-if="
                !subject_reference_list || subject_reference_list.length <= 0
              "
                 class="datumNull">
              暂无推荐参考书
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="教辅资料"
                     name="second">
          <div class="view"
               style="cursor: pointer"
               @click="hanledDatumMaterials">
            <div class="datum"
                 v-for="(item, index) in subject_material_list"
                 :key="index">
              <div>专业课名称：{{ item.subject_name }}</div>
              <div v-for="(its, ins) in item.material_list">
                教辅资料：{{ its.material_name }}
              </div>
            </div>
            <div v-if="!subject_material_list || subject_material_list.length <= 0"
                 class="datumNull">
              暂无教辅资料
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="专业课考情分析"
                     name="third">
          <el-input type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="exam_analyze">
          </el-input>
        </el-tab-pane>
        <el-tab-pane label="专业课复习规划"
                     name="fourth">
          <el-input type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="course_arrange">
          </el-input>
        </el-tab-pane>
      </el-tabs>
    </el-card>

    <!-- 已提交 -->
    <el-card style="margin-top: 20px">
      <div class="title">
        <div class="title-lef">
          课程计划（已提交）<span style="margin-left: 8px">已计划<span style="color: #409eff">{{
              plan_exist_lesson_hour
            }}</span>小时/总{{ total_lesson_hour }}小时</span>
        </div>
        <div class="title-rig">
          <el-form class="search-wrap"
                   ref="searchForm"
                   :inline="true"
                   :model="queryForm"
                   size="small"
                   label-position="left">
            <el-form-item label="状态">
              <el-select @change="stageChange"
                         style="width: 112px"
                         v-model="queryForm.plan_statuses"
                         placeholder="请选择">
                <el-option v-for="(item, index) in filters.plan_status"
                           :key="index"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="课程阶段">
              <el-select style="width: 112px"
                         v-model="queryForm.course_stages"
                         placeholder="请选择"
                         @change="stageChange">
                <el-option v-for="(item, index) in stageList"
                           :key="index"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <el-table :data="planList"
                ref="multipleTable"
                @selection-change="handleSelectionChange"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ fontSize: '13px', textAlign: 'center' }"
                :span-method="objectSpanMethod"
                border
                style="width: 100%; margin-top: 20px">
        <el-table-column label="课程阶段"
                         width="120">
          <template slot-scope="scope">
            {{
              scope.row.course_stage == 0
                ? "基础阶段"
                : scope.row.course_stage == 1
                ? "强化阶段"
                : "冲刺阶段"
            }}
          </template>
        </el-table-column>
        <el-table-column type="selection"
                         width="55"> </el-table-column>
        <el-table-column type="index"
                         label="序号"
                         width="80">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="plan_lesson_hour"
                         label="状态"
                         width="120">
          <template slot-scope="scope">
            <el-tag :type="getTeacherPlanColors(scope.row.plan_status)">
              <!-- {{ scope.row.plan_status }} -->
              {{ getTeacherPlanStatuss(scope.row.plan_status) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="上课时间"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.plan_start_time
                ? $moment(scope.row.plan_start_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="plan_lesson_hour"
                         label="上课时长"
                         width="100">
          <template slot-scope="scope">
            {{ scope.row.plan_lesson_hour }}小时
          </template>
        </el-table-column>
        <el-table-column prop="course_theme"
                         label="课程主题"
                         width="300">
        </el-table-column>
        <el-table-column prop="remark"
                         label="备注"
                         width="300">
        </el-table-column>
        <el-table-column prop="teacher_realname"
                         label="提交人"
                         width="150">
        </el-table-column>
        <el-table-column label="创建时间"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="更新时间"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>

        <el-table-column label="操作"
                         width="200"
                         align="center"
                         fixed="right">
          <!-- :disabled="scope.row.id" -->
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     :disabled="scope.row.plan_status != 0"
                     @click="handleAudit(scope.row, 1)">通过</el-link>
            <el-link type="primary"
                     :underline="false"
                     :disabled="scope.row.plan_status != 0"
                     @click="handleAudit(scope.row, 2)">拒绝</el-link>

            <el-link type="primary"
                     :underline="false"
                     @click="handleUp(scope.row)"
                     :disabled="
                scope.row.plan_status != 0 &&
                scope.row.plan_status != 20 &&
                scope.row.plan_status != 21 &&
                scope.row.plan_status != 22 &&
                scope.row.plan_status != 5
              ">编辑</el-link>
            <el-link type="danger"
                     :underline="false"
                     @click="handleDel(scope.row)"
                     v-if="scope.row.plan_status == 0 || scope.row.plan_status == 5">删除</el-link>
            <el-link type="danger"
                     :underline="false"
                     @click="handleEdit(scope.row)"
                     v-else
                     :disabled="
                scope.row.plan_status == 6 ||
                scope.row.plan_status == 40 ||
                scope.row.plan_status == 41 ||
                scope.row.plan_status == 42 ||
                scope.row.plan_status == 43 ||
                scope.row.plan_status == 45 ||
                scope.row.plan_status == 46
              ">取消</el-link>
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top: 16px">
        <el-button type="warning"
                   @click="batchAudit()"
                   :disabled="ids.length <= 0">批量通过</el-button>
        <el-button type="danger"
                   @click="batchCancle()"
                   :disabled="ids.length <= 0">批量取消</el-button>

        <el-popover placement="top"
                    width="160"
                    v-model="visibles"
                    style="marginLeft:10px"
                    popper-class="el-popover-self">
          <p @click="planAdvance"
             class="plan">计划批量提前</p>
          <p @click="planForward"
             class="plan">计划批量延后</p>

          <el-button slot="reference"
                     type="primary"
                     :disabled="ids.length <= 0">
            批量调整</el-button>
        </el-popover>

        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(queryForm.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(queryForm.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>

    <!-- 待提交 -->
    <el-card style="margin-top: 20px">
      <div class="title">
        <div class="title-lef">
          课程计划（待提交）<span style="margin-left: 8px">已计划<span style="color: #409eff">{{ proHour }}</span>小时/剩余{{
              (total_lesson_hour - plan_exist_lesson_hour).toFixed(2)
            }}小时</span>
        </div>
        <div class="title-rig">
          <el-form class="search-wrap"
                   ref="searchForm"
                   :inline="true"
                   :model="queryForm"
                   size="small"
                   label-position="left">
            <el-button icon="el-icon-plus"
                       size="small"
                       plain
                       @click="aloneOpen()">单条创建</el-button>
            <el-button icon="el-icon-plus"
                       size="small"
                       plain
                       @click="batchOpen()">批量创建</el-button>
          </el-form>
        </div>
      </div>
      <el-table :data="proList"
                height="360"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ fontSize: '13px', textAlign: 'center' }"
                border
                style="width: 100%; margin-top: 20px">
        <el-table-column label="课程阶段"
                         width="120">
          <template slot-scope="scope">
            {{
              scope.row.course_stage == 0
                ? "基础阶段"
                : scope.row.course_stage == 1
                ? "强化阶段"
                : "冲刺阶段"
            }}
          </template>
        </el-table-column>
        <el-table-column type="index"
                         label="序号"
                         width="80">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="上课时间"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.plan_start_time
                ? $moment(scope.row.plan_start_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="plan_lesson_hour"
                         label="上课时长"
                         width="100">
          <template slot-scope="scope">
            {{ scope.row.plan_lesson_hour }}小时
          </template>
        </el-table-column>
        <el-table-column prop="course_theme"
                         label="课程主题"
                         width="300">
        </el-table-column>
        <el-table-column prop="remark"
                         label="备注"
                         width="500">
        </el-table-column>
        <!--<el-table-column prop="teacher_realname" label="提交人" width="150">
                </el-table-column>
                <el-table-column label="创建时间" width="150">
                    <template slot-scope="scope">
                        {{ scope.row.create_time ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm") : "" }}
                    </template>
                </el-table-column>
                <el-table-column label="更新时间" width="150">
                    <template slot-scope="scope">
                        {{ scope.row.update_time ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm") : "" }}
                    </template>
                </el-table-column> -->
        <el-table-column label="操作"
                         width="200"
                         align="center"
                         fixed="right">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleUp(scope.row, scope.$index)">编辑</el-link>
            <el-link type="danger"
                     :underline="false"
                     @click="proDel(scope.$index)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog top="5vh"
               :title="titleVisible"
               :visible.sync="dialogFormVisible"
               @close="emitClose"
               :close-on-click-modal="false"
               width="620px">
      <div class="time">
        <div class="time-left">选择调整时间：</div>
        <div class="time-right">
          <div class="select-wrapper">
            <select v-model="selectedDays"
                    class="select-box">
              <option v-for="day in days"
                      :key="day"
                      :value="day">{{ day }} 天</option>
            </select>
          </div>
          <div class="select-wrapper">
            <select v-model="selectedHours"
                    class="select-box">
              <option v-for="hour in hours"
                      :key="hour"
                      :value="hour">{{ hour }} 小时</option>
            </select>
          </div>
          <div class="select-wrapper">
            <select v-model="selectedMinutes"
                    class="select-box">
              <option v-for="minute in minutes"
                      :key="minute"
                      :value="minute">{{ minute }} 分钟</option>
            </select>
          </div>

        </div>

      </div>
      <!-- <el-form :model="planForm"
               label-position="right"
               ref="formRules"
               label-width="100px"
               size="small">

        <el-form-item label="调整时间"
                      prop="adjustTime">
          <el-date-picker default-time="19:00:00"
                          value-format="timestamp"
                          v-model="planForm.adjustTime"
                          type="datetime"
                          format="yyyy-MM-dd HH:mm"
                          style="width: 220;"
                          placeholder="选择调整时间">
          </el-date-picker>
        </el-form-item>
      </el-form> -->
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button type="primary"
                   @click="submitPlan('formRules')">确定</el-button>
      </div>
    </el-dialog>
    <PlanReviewAdd ref="planReviewAdd"
                   @submit="preCommit"
                   @updateHour="getGradeOne"
                   @refresh="getCoursePlanlist">
    </PlanReviewAdd>
    <BatchrPlanReviewAdd ref="batchrPlanReviewAdd"
                         @submit="preCommit"
                         @updateHour="getGradeOne">
    </BatchrPlanReviewAdd>
    <PlanDatum ref="planDatum"
               @submit="referenceBook"></PlanDatum>
    <PlanDatumMaterials ref="planDatumMaterials"
                        @submit="teachingMaterials"></PlanDatumMaterials>
  </div>
</template>

<script>
import { getTeacherPlanColor, getTeacherPlanStatus } from '@/utils/status.js'
import {
  gradeOne,
  coursePlanlist,
  coursePlanDelete,
  coursePlanCheck,
  coursePlanGroupDetail,
  upCoursePlanGroup,
  coursePlanEdit,
  byTeacher,
  checkList,
  companyOne,
  courseCancle,
  courseTimeDelay,
} from '@/request/api'
import { getStorage, adminUrl } from '@/utils/helper'
import Clipboard from 'clipboard'

import PlanReviewAdd from '@/components/PlanComponents/PlanReviewAdd/index'
import BatchrPlanReviewAdd from '@/components/PlanComponents/BatchrPlanReviewAdd/index'
import PlanDatum from '@/components/PlanComponents/PlanDatum/index'
import PlanDatumMaterials from '@/components/PlanComponents/PlanDatumMaterials/index'
export default {
  components: {
    PlanReviewAdd,
    BatchrPlanReviewAdd,
    PlanDatum,
    PlanDatumMaterials,
  },
  watch: {
    selectedDays(newVal) {
      this.emitSelectedTime()
    },
    selectedHours(newVal) {
      this.emitSelectedTime()
    },
    selectedMinutes(newVal) {
      this.emitSelectedTime()
    },
  },
  data() {
    return {
      selectedDays: 0,
      selectedHours: 0,
      selectedMinutes: 0,
      days: Array.from({ length: 365 }, (v, k) => k),
      hours: Array.from({ length: 24 }, (v, k) => k),
      minutes: Array.from({ length: 60 }, (v, k) => k),
      timestamp: 0,
      visibles: false,
      planForm: {},
      adjustTime: '',
      query: {},
      dialogFormVisible: false,
      titleVisible: '',
      adjustPlan: [
        {
          label: '计划批量提前',
          value: 0,
        },
        { label: '计划批量延后', value: 1 },
      ],
      clipboardText: '',
      grade_id: this.$route.query.grade_id,
      teacher_id: this.$route.query.teacher_id,
      activeName: 'first',
      textarea1: 'sdsdsads',
      textarea2: '',
      textarea3: '',
      textarea4: '',
      subject_reference_list: null, //推荐参考书
      subject_material_list: null, //教辅资料
      exam_analyze: null, //考情分析
      course_arrange: null, //课程安排
      filters: {
        plan_status: [
          /*     { label: "待审核", value: 0 },
                        { label: "待上课", value: 1 },
                        { label: "待确认", value: 2 },
                        { label: "已确认", value: 3 },
                        { label: "已完成", value: 4 },
                        { label: "已拒绝", value: 5 }, */
          { label: '全部', value: '' },
          { label: '待审核', value: 0 },
          { label: '已通过', value: 99 },
          { label: '已拒绝（计划）', value: 5 },
          { label: '已取消', value: 6 },
          { label: '空状态', value: 7 },
          { label: '待上课', value: 20 },
          { label: '待提交', value: 21 },
          { label: '已超期', value: 22 },
          { label: '待确认', value: 40 },
          { label: '待回访', value: 41 },
          { label: '已拒绝（课时）', value: 42 },
          { label: '已完成', value: 43 },
          { label: '已完结', value: 45 },
          { label: '已暂停', value: 46 },
          { label: '上课中', value: 1 },
        ],
      },
      stageList: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '基础阶段',
          value: '0',
        },
        {
          label: '强化阶段',
          value: '1',
        },
        {
          label: '冲刺阶段',
          value: '2',
        },
      ],
      queryForm: {
        plan_statuses: '',
        course_stages: '',
        pageIndex: 1,
        pageSize: 10,
        teacher_id: this.$route.query.teacher_id,
      },
      pageTotal: 0,
      gradeDetail: {},
      tableData: [],
      rate: 0.75,
      proList: [],
      proHour: 0,
      planList: [],
      // proPlanList:[],
      basics: [],
      intensify: [],
      sprint: [],
      totalClassHours: null, //改班级剩余时长
      total_lesson_hour: null, //计划总时长
      plan_exist_lesson_hour: null, //已提交总时长
      insertId: null,
      multipleSelection: [],
      ids: [],
      token: '',
      rules: [],
    }
  },
  created() {
    // if (getStorage("box_user")) {
    //   this.rate = getStorage("box_user").student_company_lesson_hour_rate
    //     ? Number(getStorage("box_user").student_company_lesson_hour_rate)
    //     : 0.75;
    // }
    this.getGradeOne()
    this.getCoursePlanlist()
    this.getCoursePlanGroupDetail()
  },
  methods: {
    emitSelectedTime() {
      this.timestamp =
        this.selectedDays * 24 * 60 * 60 * 1000 +
        this.selectedHours * 60 * 60 * 1000 +
        this.selectedMinutes * 60 * 1000
      // const currentTime = new Date()
      // const futureTime = new Date(currentTime.getTime() + totalMilliseconds)
      // this.timestamp = futureTime.getTime()
    },
    planAdvance() {
      this.titleVisible = '计划批量提前'
      this.dialogFormVisible = true
    },
    planForward() {
      this.titleVisible = '计划批量延后'
      this.dialogFormVisible = true
    },
    emitClose() {
      this.dataForm = {}
      this.dialogFormVisible = false
      this.selectedDays = 0
      this.selectedHours = 0
      this.selectedMinutes = 0
    },
    getCompanyOne(id) {
      companyOne({ id: id }).then((response) => {
        let res = response.data
        if (res.code == 0) {
          this.rate = res.data.lesson_hour_hour_rate || 0.75
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.ids = []
      this.multipleSelection.forEach((item) => {
        this.ids.push(item.id)
      })
    },
    submitPlan() {
      let arr_course_plan_id = []
      this.ids.map((item) => {
        arr_course_plan_id.push(item)
      })
      if (this.titleVisible == '计划批量提前') {
        this.timestamp = -this.timestamp
      }
      let data = {
        grade_id: this.grade_id,
        arr_course_plan_id: arr_course_plan_id,
        start_time_delay_time: this.timestamp,
      }
      courseTimeDelay(data).then((res) => {
        if (res.data.code == 0) {
          this.dialogFormVisible = false
          this.planForm = {}
          this.getCoursePlanlist()
          this.getGradeOne()
          this.$message({
            type: 'success',
            message: '操作成功!',
          })
          this.selectedDays = 0
          this.selectedHours = 0
          this.selectedMinutes = 0
        }
      })
    },
    batchAudit() {
      let list = this.multipleSelection.find((item) => item.plan_status != 0)
      if (list) {
        this.$message({
          type: 'warning',
          message: '存在不需要审核的计划',
        })
        return
      }
      this.$confirm('是否批量通过?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          checkList({
            ids: this.ids,
            is_access: 1,
          }).then((res) => {
            if (res.data.code == 0) {
              this.getCoursePlanlist()
              this.getGradeOne()
              this.$message({
                type: 'success',
                message: '操作成功!',
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },

    batchCancle() {
      let data = { rows: [] }
      this.ids.map((item) => {
        data.rows.push({
          id: item,
          plan_status: 6,
        })
      })

      console.log('rows', data)
      this.$confirm('是否批量取消?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          courseCancle(data).then((res) => {
            if (res.data.code == 0) {
              this.getCoursePlanlist()
              this.getGradeOne()
              this.$message({
                type: 'success',
                message: '操作成功!',
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    async copy() {
      let str = adminUrl()
      let data = await this.$axios({
        url: `${str}/api/v1/back/member/getTempUserToken`,
        method: 'post', //可以省略 不写默认是get
        data: {},
        headers: {
          // 设置请求头
          Authorization: localStorage.getItem('admin_token'),
        },
      })

      if (location) {
        if (location.host.includes('manage.yanshizi.com')) {
          this.clipboardText = `https://lesson.kaoyanbox.com/#/pagesA/planPreview/index?grade_id=${
            this.grade_id
          }&teacher_id=${this.teacher_id}&token=${window.btoa(data.data.data)}`
        } else {
          this.clipboardText = `http://localhost:8080/#/pagesA/planPreview/index?grade_id=${
            this.grade_id
          }&teacher_id=${this.teacher_id}&token=${window.btoa(data.data.data)}`
          // this.clipboardText = `http://test-senior.kaoyanbox.net/#/pagesA/planPreview/index?grade_id=${this.grade_id}&teacher_id=${this.teacher_id}&token=${window.btoa(data.data.data)}`
        }
      }

      this.$nextTick(() => {
        this.$refs.copys.click()
      })
    },
    copyWord() {
      var clipboard = new Clipboard('.copy')
      clipboard.on('success', (e) => {
        this.$message({
          message: '复制成功！',
          type: 'success',
        })
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({
          message: '该浏览器不支持自动复制',
          type: 'error',
        })
        // 释放内存
        clipboard.destroy()
      })
    },
    // 预提交
    preCommit(list) {
      // console.log("CC", list)
      if (list.proIndex || list.proIndex == 0) {
        this.proList[list.proIndex] = list.obj
      } else {
        this.proList = [...list, ...this.proList]
      }
      // console.log("lis",this.proList)
      let hour = 0
      this.proList.forEach((item) => {
        hour += Number(item.plan_lesson_hour)
      })
      this.proHour = Number(hour).toFixed(2)
      // this.dataListSort(this.proList,'pro')
    },

    // 教辅资料
    teachingMaterials(data) {
      this.subject_material_list = data
    },
    hanledDatumMaterials() {
      this.$refs.planDatumMaterials.open(this.subject_material_list)
    },
    // 推荐参考书
    referenceBook(data) {
      this.subject_reference_list = data
      // console.log("data",data)
    },
    hanledDatum() {
      this.$refs.planDatum.open(this.subject_reference_list)
    },
    // 单条创建
    aloneOpen() {
      this.$refs.planReviewAdd.open({
        totalClassHours: (
          Number(this.totalClassHours) - Number(this.proHour)
        ).toFixed(2),
        grade_id: this.grade_id,
        teacher_id: this.teacher_id,
      })
    },

    //批量创建
    batchOpen() {
      this.$refs.batchrPlanReviewAdd.open({
        totalClassHours: (
          Number(this.totalClassHours) - Number(this.proHour)
        ).toFixed(2),
        grade_id: this.grade_id,
        teacher_id: this.teacher_id,
      })
    },
    // 编辑
    handleUp(row, proIndex) {
      // console.log('can', row)
      if (proIndex || proIndex == 0) {
        this.$refs.planReviewAdd.open({
          proIndex: proIndex,
          totalClassHours: (
            Number(this.totalClassHours) - Number(this.proHour)
          ).toFixed(2),
          grade_id: this.grade_id,
          teacher_id: this.teacher_id,
          dataForm: row,
        })
      } else {
        if (
          row.plan_status == 20 ||
          row.plan_status == 21 ||
          row.plan_status == 22
        ) {
          this.$refs.planReviewAdd.open({
            type: true,
            totalClassHours: (
              Number(this.totalClassHours) + Number(row.plan_lesson_hour)
            ).toFixed(2),
            grade_id: this.grade_id,
            teacher_id: this.teacher_id,
            dataForm: row,
          })
        } else {
          this.$refs.planReviewAdd.open({
            totalClassHours: (
              Number(this.totalClassHours) + Number(row.plan_lesson_hour)
            ).toFixed(2),
            grade_id: this.grade_id,
            teacher_id: this.teacher_id,
            dataForm: row,
          })
        }
      }
    },
    handleAudit(row, type) {
      let data = {}
      if (type == 1) {
        data = {
          id: row.id,
          is_access: 1,
        }
      } else {
        data = {
          id: row.id,
          is_access: 0,
        }
      }

      this.$confirm(`是否${type == 1 ? '通过' : '拒绝'}计划?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          coursePlanCheck(data).then((res) => {
            if (res.data.code == 0) {
              this.getCoursePlanlist()
              this.$message({
                type: 'success',
                message: '审核成功!',
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消审核',
          })
        })
    },
    // 取消
    handleEdit(row) {
      this.$confirm('是否取消计划?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          coursePlanEdit({
            id: row.id,
            plan_status: 6,
          }).then((res) => {
            if (res.data.code == 0) {
              this.getCoursePlanlist()
              this.getGradeOne()
              this.$message({
                type: 'success',
                message: '取消成功!',
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 删除
    proDel(index) {
      this.proList.splice(index, 1)
    },
    handleDel(row) {
      this.$confirm('是否删除计划?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          coursePlanDelete({
            id: row.id,
          }).then((res) => {
            if (res.data.code == 0) {
              this.getCoursePlanlist()
              this.getGradeOne()
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 获取计划组
    getCoursePlanGroupDetail() {
      coursePlanGroupDetail({
        grade_id: this.grade_id,
        teacher_id: this.teacher_id,
      }).then((res) => {
        if (res.data.code == 0) {
          this.insertId = res.data.data.id
          if (res.data.data.subject_reference_list) {
            this.subject_reference_list = JSON.parse(
              res.data.data.subject_reference_list
            )
          }
          if (res.data.data.subject_material_list) {
            this.subject_material_list = JSON.parse(
              res.data.data.subject_material_list
            )
          }
          this.exam_analyze = res.data.data.exam_analyze
          this.course_arrange = res.data.data.course_arrange
          // console.log("cc", this.subject_reference_list, this.subject_material_list)
        }
        // console.log("计划组详情", res)
      })
    },
    // 列表排序
    dataListSort(list) {
      list.forEach((item) => {
        if (item.course_stage == 0) {
          this.basics.push(item)
        } else if (item.course_stage == 1) {
          this.intensify.push(item)
        } else {
          this.sprint.push(item)
        }
      })
      this.basics.sort((a, b) => {
        return a.plan_start_time - b.plan_start_time
      })
      this.intensify.sort((a, b) => {
        return a.plan_start_time - b.plan_start_time
      })
      this.sprint.sort((a, b) => {
        return a.plan_start_time - b.plan_start_time
      })
      this.planList = [...this.basics, ...this.intensify, ...this.sprint]

      if (this.planList.length > 0) {
        this.planList.forEach((row) => {
          if (row.plan_status != 0) {
            this.$refs.multipleTable.toggleRowSelection(row)
          }
        })
      }
    },

    // 获取详情
    getGradeOne() {
      gradeOne({
        id: this.grade_id,
      }).then((res) => {
        if (res.data.code == 0) {
          if (res.data.data.students) {
            this.getCompanyOne(res.data.data.students[0].cid)
          }
          this.gradeDetail = res.data.data
          this.totalClassHours =
            res.data.data.grade_plan_data.plan_last_lesson_hour
          this.total_lesson_hour =
            res.data.data.grade_plan_data.plan_total_lesson_hour
          this.plan_exist_lesson_hour =
            res.data.data.grade_plan_data.plan_exist_lesson_hour
          // console.log("cccc",(Number(this.totalClassHours) + Number(this.proHour)).toFixed(2))
          this.$refs.planReviewAdd.updateHour(
            (Number(this.totalClassHours) - Number(this.proHour)).toFixed(2)
          )
          this.$refs.batchrPlanReviewAdd.updateHour(
            (Number(this.totalClassHours) - Number(this.proHour)).toFixed(2)
          )
        }
      })
    },
    // 获取计划
    getCoursePlanlist() {
      this.basics = []
      this.intensify = []
      this.sprint = []
      coursePlanlist({
        ...this.queryForm,
        is_all: 1,
        // is_log_ref: 0,
        grade_id: this.grade_id, //班级id
      }).then((res) => {
        if (res.data.code == 0) {
          // this.proPlanList = res.data.data.list
          this.pageTotal = res.data.data.count
          this.dataListSort(res.data.data.list)
          // if(res.data.data.count == 0){
          //     this.$message({
          //     type: 'warning',
          //     message: '暂无计划!'
          // });
          // }
        }
        // console.log(this.planList, 'res11111')
      })
    },

    // 提交
    submit() {
      let word = ''
      if (
        !this.subject_reference_list ||
        this.subject_reference_list.length <= 0
      ) {
        word += '“推荐参考书”，'
      }
      if (
        !this.subject_material_list ||
        this.subject_material_list.length <= 0
      ) {
        word += '“教辅资料”，'
      }
      if (!this.exam_analyze) {
        word += '“考情分析”，'
      }
      if (!this.course_arrange) {
        word += '“课程安排”，'
      }
      if (word) {
        word += '为空，'
      }
      this.$confirm(`${word}是否进行提交?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (this.proList.length <= 0) {
            this.coursePlanGroup()
            return
          }
          byTeacher({
            grade_id: this.grade_id,
            teacher_id: this.teacher_id,
            arr_data: this.proList,
          }).then((res) => {
            if (res.data.code == 0) {
              this.coursePlanGroup()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消提交',
          })
        })
    },

    // 资料提交
    coursePlanGroup() {
      upCoursePlanGroup({
        id: this.insertId,
        grade_id: this.grade_id,
        teacher_id: this.teacher_id,
        subject_reference_list: this.subject_reference_list,
        subject_material_list: this.subject_material_list,
        exam_analyze: this.exam_analyze,
        course_arrange: this.course_arrange,
      }).then((res) => {
        if (res.data.code == 0) {
          this.proHour = 0
          this.proList = []
          this.getCoursePlanlist()
          this.getCoursePlanGroupDetail()
          this.$message({
            type: 'success',
            message: '提交成功!',
          })
        }
      })
    },

    // 辅导科目
    getSubject(e) {
      let subjectName = ''
      if (e && e.length > 0) {
        e.forEach((item) => {
          subjectName = subjectName + item.subject_name + ' '
        })
        return subjectName
      }
      return ''
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex == 0 && row.course_stage == 0) {
          return {
            rowspan: this.basics.length,
            colspan: 1,
          }
        } else if (rowIndex == this.basics.length && row.course_stage == 1) {
          return {
            rowspan: this.intensify.length,
            colspan: 1,
          }
        } else if (
          rowIndex == this.basics.length + this.intensify.length &&
          row.course_stage == 2
        ) {
          return {
            rowspan: this.sprint.length,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    },
    getTeacherPlanColors(e) {
      return getTeacherPlanColor(e)
    },
    getTeacherPlanStatuss(e) {
      return getTeacherPlanStatus(e)
    },
    stageChange() {
      this.queryForm.pageIndex = 1
      this.getCoursePlanlist()
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.getCoursePlanlist()
    },
    handleCurrentChange(val) {
      this.queryForm.pageIndex = val
      this.getCoursePlanlist()
    },
  },
}
</script>
<style>
.el-popover.el-popper.el-popover-self {
  min-width: 140px !important;
  width: 140px !important;
  padding: 0;
}
</style>

<style lang="less" scoped>
.time {
  display: flex;
  // border: 1px solid blue;
}
.time-left {
  line-height: 80px;
  margin-right: 10px;
}
.time-right {
  // border: 1px solid red;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}
.select-wrapper {
  position: relative;
  display: inline-block;
}

.select-box {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.select-box:focus {
  outline: none;
  border-color: #007bff;
}

.select-wrapper::after {
  content: '▼';
  font-size: 12px;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #888;
}
.plan {
  margin: 4px auto;
  // border: 1px solid red;
  width: 80%;
  text-align: center;
  line-height: 30px;
}
.plan:hover {
  cursor: pointer;
  color: #409eff;
}
.tabs {
  /deep/.el-textarea__inner {
    height: 164px !important;
  }
}

.view {
  width: 100%;
  height: 164px !important;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  box-sizing: border-box;
  overflow-y: scroll;
  padding: 5px 15px;

  .datum {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #303133;
    margin-bottom: 10px;
    line-height: 20px;
  }
  .datumNull {
    color: #dcdfe6;
    font-size: 15px;
    line-height: 1.5;
    vertical-align: bottom;
    font-weight: normal;
  }
}

.search-wrap {
  /deep/.el-form-item {
    margin-bottom: 0 !important;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title-lef {
    font-size: 16px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #303133;

    span {
      font-size: 14px;
      font-weight: 400;
      color: #303133;
    }
  }

  .title-rig {
  }
}
</style>