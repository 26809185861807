<template>
  <div>
    <el-dialog append-to-body
               top="5vh"
               title="订单操作记录"
               :visible.sync="dialogFormVisible"
               @close="handleToClose"
               :close-on-click-modal="false"
               width="80%">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small"
               label-position="left">
        <el-form-item label="操作人">
          <el-input v-model.trim="query.handle_user_keyword"
                    placeholder="请输入操作人姓名/手机号/所在机构"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="类别">
          <el-select v-model="query.types"
                     placeholder="请选择"
                     @change="fetchList"
                     multiple
                     clearable>
            <el-option v-for="item in filters.types"
                       :key="item.id"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-table :data="result"
                  :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                  :cell-style="{ fontSize: '13px' }"
                  @selection-change="handleSelectionChange"
                  border>
          <!--       <el-table-column
            type="selection"
            width="50"
            align="center"
          > </el-table-column> -->
          <el-table-column prop="id"
                           label="ID"
                           align="center"
                           width="80">
          </el-table-column>
          <el-table-column prop="order_no"
                           label="订单编号"
                           align="center"
                           width="150">
          </el-table-column>
          <el-table-column label="类别"
                           align="center"
                           width="120"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag size="small"
                      v-if="scope.row.type == 1">订单创建</el-tag>
              <el-tag size="small"
                      v-if="scope.row.type == 2">订单修改</el-tag>
              <el-tag size="small"
                      type="info"
                      v-if="scope.row.type == 3">订单取消</el-tag>
              <el-tag size="small"
                      v-if="scope.row.type == 4">有人接单</el-tag>
              <el-tag size="small"
                      type="info"
                      v-if="scope.row.type == 5">取消接单</el-tag>
              <el-tag size="small"
                      type="info"
                      v-if="scope.row.type == 6">自动取消</el-tag>
              <el-tag type="success"
                      size="small"
                      v-if="scope.row.type == 7">提交老师</el-tag>
              <el-tag type="success"
                      size="small"
                      v-if="scope.row.type == 8">订单支付</el-tag>
              <el-tag type="success"
                      size="small"
                      v-if="scope.row.type == 9">确认老师</el-tag>
              <el-tag type="success"
                      size="small"
                      v-if="scope.row.type == 10">自动确认</el-tag>
              <el-tag type="danger"
                      size="small"
                      v-if="scope.row.type == 11">驳回老师</el-tag>
              <el-tag type="success"
                      size="small"
                      v-if="scope.row.type == 12">订单完成</el-tag>
              <el-tag type="warning"
                      size="small"
                      v-if="scope.row.type == 13">延长确认</el-tag>
              <el-tag type="warning"
                      size="small"
                      v-if="scope.row.type == 14">系统消息</el-tag>
              <el-tag type="warning"
                      size="small"
                      v-if="scope.row.type == 15">系统取消</el-tag>
              <el-tag type="warning"
                      size="small"
                      v-if="scope.row.type == 16">订单刷新</el-tag>
              <el-tag type="warning"
                      size="small"
                      v-if="scope.row.type == 17">留言</el-tag>
              <el-tag type="warning"
                      size="small"
                      v-if="scope.row.type == 18">自动延时</el-tag>
              <el-tag type="warning"
                      size="small"
                      v-if="scope.row.type == 19">取消确认</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="record"
                           label="记录内容"
                           align="left"
                           min-width="320"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ scope.row.record }}</div>
              <div class="teacher-wrap"
                   v-if="scope.row.type == 7 && scope.row.receiver_commit_teacher_id">
                <div class="list-wrap"
                     v-if="teachers&&teachers.length">
                  <div class="item"
                       v-if="teachers.find(v => { return v.id == scope.row.receiver_commit_teacher_id }).teacher_apply_year">
                    <div class="label">录取年份</div>
                    <div class="value">{{ teachers.find(v => {
                      return v.id ==
                        scope.row.receiver_commit_teacher_id
                    }).teacher_apply_year }}级</div>
                  </div>
                  <div class="item">
                    <div class="label">老师姓名</div>
                    <div class="value">{{ teachers.find(v => {
                      return v.id ==
                        scope.row.receiver_commit_teacher_id
                    }).teacher_name }}</div>
                  </div>
                  <div class="item">
                    <div class="label">手机号</div>
                    <div class="value">{{ teachers.find(v => {
                      return v.id ==
                        scope.row.receiver_commit_teacher_id
                    }).teacher_phone }}</div>
                  </div>
                  <div class="item"
                       v-if="teachers.find(v => { return v.id == scope.row.receiver_commit_teacher_id }).teacher_wechat">
                    <div class="label">微信号</div>
                    <div class="value">{{ teachers.find(v => {
                      return v.id ==
                        scope.row.receiver_commit_teacher_id
                    }).teacher_wechat }}</div>
                  </div>
                  <div class="item"
                       v-if="teachers.find(v => { return v.id == scope.row.receiver_commit_teacher_id }).teacher_qq">
                    <div class="label">QQ号</div>
                    <div class="value">{{ teachers.find(v => {
                      return
                      v.id == scope.row.receiver_commit_teacher_id
                    }).teacher_qq }}</div>
                  </div>
                  <div class="item">
                    <div class="label">初试总分</div>
                    <div class="value">{{ teachers.find(v => {
                      return v.id ==
                        scope.row.receiver_commit_teacher_id
                    }).teacher_first_exam_score }}</div>
                  </div>
                  <div class="item"
                       v-if="teachers.find(v => { return v.id == scope.row.receiver_commit_teacher_id }).remark">
                    <div class="label">备注内容</div>

                    <div class="value"
                         style="width: 200px;box-sizing: border-box;">
                      {{ teachers.find(v => { return v.id == scope.row.receiver_commit_teacher_id }).remark }}
                    </div>

                  </div>
                </div>

              </div>
              <div class="img-list"
                   v-if="scope.row.record_img_urls">
                <img v-for="(item, index) in scope.row.record_img_urls.split(',')"
                     :key="index"
                     :src="item"
                     alt=""
                     srcset=""
                     @click="openViewer(scope.row.record_img_urls.split(','),index)">
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作人"
                           align="left"
                           min-width="200"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.handle_user_type == 3">系统</span>
              <span v-else-if="scope.row.handle_user_type == 1">{{
                scope.row.sender_company_user_obj ? `${scope.row.sender_company_user_obj.company_ref_name}/${scope.row.sender_company_user_obj.alias}/${scope.row.sender_company_user_obj.phone}` : ''
              }}</span>
              <span v-else-if="scope.row.handle_user_type == 2">{{
                scope.row.receiver_company_user_obj ? `${scope.row.receiver_company_user_obj.company_ref_name}/${scope.row.receiver_company_user_obj.alias}/${scope.row.receiver_company_user_obj.phone}` : ''
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间"
                           align="center"
                           width="150">
            <template slot-scope="scope">
              {{ scope.row.create_time ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm") : "无" }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="operation">
        <div>
          <el-button size="small"
                     @click="handleToLog()">新增记录</el-button>
          <!--     <el-button
            size="mini"
            type="danger"
            @click="handleToDeleteAll()"
          >删除</el-button> -->
        </div>
        <el-pagination background
                       layout="total, prev, pager, next, sizes, jumper"
                       :current-page="Number(query.pageIndex)"
                       :page-sizes="[10, 20, 50, 100, 500]"
                       :page-size="Number(query.pageSize)"
                       :total="Number(pageTotal)"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"></el-pagination>
      </div>

    </el-dialog>

    <el-dialog append-to-body
               top="5vh"
               title="记录"
               :visible.sync="logDialog"
               @close="logDialog = false"
               :close-on-click-modal="false"
               width="700px">
      <el-form :model="dataForm"
               label-position="right"
               ref="formRules"
               :rules="rules"
               label-width="100px"
               size="small">
        <el-form-item label="内容："
                      prop="record">
          <el-input v-model="dataForm.record"
                    type="textarea"
                    placeholder="请输入内容或直接粘贴图片"
                    style="width: 100%;"
                    @paste.native="handlePaste"></el-input>
        </el-form-item>
        <el-form-item label="图片："
                      prop="">
          <el-upload :action="imgUploadUrl"
                     list-type="picture-card"
                     :file-list="fileList"
                     :limit="3"
                     :on-success="handleSuccess">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="logDialog = false">取消</el-button>
        <el-button type="primary"
                   @click="submit('formRules')">确定</el-button>
      </div>
    </el-dialog>
    <el-image-viewer v-if="showViewer"
                     :url-list="imgs"
                     :zIndex="999999999"
                     :on-close="closeViewer"
                     :initial-index="initialIndex" />
  </div>
</template>
    
<script>
import {} from '@/request/api'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { imgUploadUrl } from '@/request/http'
import { adminUrl, crmUrl } from '@/utils/helper'
import { log } from 'mathjs'

export default {
  components: {
    ElImageViewer,
  },
  props: {
    recordItem: {
      default: () => {
        return {}
      },
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null
      },
    },
    handle_receiver_id: {
      default: () => {
        return null
      },
    },
  },
  data() {
    return {
      initialIndex: 0,
      showViewer: false,
      dataForm: {
        record: '',
      },
      imgs: [],

      logDialog: false,
      imgUploadUrl: imgUploadUrl(),
      fileList: [],
      rules: {
        record: [{ required: true, message: '请输入内容', trigger: 'blur' }],
      },

      dialogFormVisible: this.isVisible,
      result: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 0,
      chooseList: [],
      filters: {
        types: [
          {
            label: '订单创建',
            value: 1,
          },
          {
            label: '订单修改',
            value: 2,
          },
          {
            label: '订单取消',
            value: 3,
          },
          {
            label: '有人接单',
            value: 4,
          },
          {
            label: '取消接单',
            value: 5,
          },
          {
            label: '自动取消',
            value: 6,
          },
          {
            label: '提交老师',
            value: 7,
          },
          {
            label: '订单支付',
            value: 8,
          },
          {
            label: '确认老师',
            value: 9,
          },
          {
            label: '自动确认',
            value: 10,
          },
          {
            label: '驳回老师',
            value: 11,
          },
          {
            label: '订单完成',
            value: 12,
          },
          {
            label: '延时确认',
            value: 13,
          },
          {
            label: '客服消息',
            value: 14,
          },
          {
            label: '系统提示',
            value: 15,
          },
          {
            label: '订单刷新',
            value: 16,
          },
          {
            label: '留言',
            value: 17,
          },
          {
            label: '自动延时',
            value: 18,
          },
          {
            label: '取消确认',
            value: 19,
          },
        ],
      },
      teachers: [],
    }
  },
  mounted() {
    console.log(' 44', this.recordItem)
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
      if (val) {
        this.result = []
        this.fetchList()
      }
    },
  },
  methods: {
    // 关闭查看器
    closeViewer() {
      this.showViewer = false
    },
    openViewer(url, index) {
      this.imgs = url
      this.initialIndex = index
      // console.log("cc", url)
      this.showViewer = true
    },

    submit(rules) {
      let imgList = []
      this.fileList.forEach((item) => {
        imgList.push(item.url)
      })
      // console.log("tu",this.fileList.toString)
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let str = crmUrl()
          this.$axios({
            url: `${str}/api/v1/sharePlatform/findOrder/record`,
            method: 'post', //可以省略 不写默认是get
            data: {
              help_find_order_id: this.id,
              sender_company_user_id:
                this.recordItem?.sender_company_user_idsender_company_user_id,
              type: 14,
              receiver_company_user_id:
                this.recordItem?.receiver_company_user_ids_real,
              record: this.dataForm.record,
              record_img_urls: imgList.toString(),
            },
          }).then((res) => {
            this.fetchList()
            this.$message({
              message: '新增成功',
              type: 'success',
            })
            this.logDialog = false
            // console.log("xx",res)
          })
          // console.log("11")
        }
      })
    },
    // 文件上传成功处理
    handleSuccess(response, file, fileList) {
      this.fileList.push({
        url: response.data.url,
      })
    },
    handleToLog() {
      this.dataForm.record = ''
      this.fileList = []
      this.logDialog = true
    },
    fetchTeacherList(arr) {
      const str = crmUrl()
      for (let i = 0; i < arr.length; i++) {
        this.$axios({
          url: `${str}/api/v1/sharePlatform/findOrderHandle/orderTeacher/find`,
          method: 'get', //可以省略 不写默认是get
          params: {
            find_order_id: this.id,
            handle_receiver_id: arr[i].receiver_company_user_id,
          },
        }).then((response) => {
          let res = response.data
          if (res.code == 0) {
            this.teachers = this.teachers.concat(res.data.arr_receiver_teacher)
            // console.log('this.teachers:', this.teachers)
          }
        })
      }
    },
    handleToClose() {
      this.teachers = []
      this.query.pageIndex = 1
      //  query: {
      //   pageIndex: 1,
      //   pageSize: 10,
      //   isPaging: 1,
      // },
      this.$emit('close')
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query, {
        help_find_order_id: this.id,
      })
      if (params.types) {
        params.types = params.types.join(',')
      }
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/findOrder/record/list`,
        method: 'get', //可以省略 不写默认是get
        params: params,
      }).then((response) => {
        let res = response.data
        this.result = res.data.list || []
        this.pageTotal = res.data.count

        let arr = this.unique(
          res.data.list.filter((v) => {
            return v.type == 7
          })
        )
        if (this.recordItem.status == 3) return
        this.fetchTeacherList(arr)
      })
    },
    unique(arr) {
      const res = new Map()
      return arr.filter(
        (arr) =>
          !res.has(arr.receiver_company_user_id) &&
          res.set(arr.receiver_company_user_id, 1)
      )
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    handleToDeleteAll() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要删除的数据')
        return
      }
      this.$confirm('确定要删除这些信息吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let ids = this.chooseList.map((v) => {
          return v.id
        })
        let str = crmUrl()
        this.$axios({
          url: `${str}/api/v1/sharePlatform/findOrder/record/list`,
          method: 'delete', //可以省略 不写默认是get
          data: { ids },
        }).then((response) => {
          this.fetchList()
        })
      })
    },
  },
}
</script>
    
<style lang="less" scoped>
.img-list {
  margin-top: 12px;
  display: flex;
  align-items: center;

  img {
    width: 140px;
    height: 140px;
    margin-right: 12px;
    border-radius: 4px;
  }
}

.operation {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.teacher-wrap {
  margin-top: 16px;

  .list-wrap {
    padding: 16px;
    background: #f1f2f5;
    border-radius: 8px;
    margin-top: 16px;

    .item {
      display: flex;
      align-items: flex-start;

      .label {
        width: 90px;
        font-size: 12px;
        color: #7787a2;
      }

      .value {
        width: 200px;
        font-size: 12px;
        color: #333;
        white-space: pre-wrap;
      }
    }
  }
}
</style>