<template>
  <div class="content">
    <el-dialog top="5vh"
               title="账单列表"
               :visible.sync="dialogFormVisible"
               :close-on-click-modal="false"
               width="654px"
               @close="emitClose">
      <el-table :data="tableData"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                height="460"
                border>
        <el-table-column prop="out_trade_no"
                         label="订单ID"
                         width="180"
                         v-if="billType==3"
                         show-overflow-tooltip>

        </el-table-column>
        <el-table-column prop="order_no"
                         label="账单ID"
                         width="180"
                         v-if="billType==1||billType==2"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="source_order_type"
                         label="账单类型"
                         width="116"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="billType== 1">{{scope.row.type}}</span>
            <span v-if="billType == 2">共享账单</span>
            <span v-if="billType == 3">充值账单</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time"
                         label="账单周期"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.start_time | dateFormat("YYYY.MM.DD") }}-{{
              scope.row.end_time | dateFormat("YYYY.MM.DD")
            }}
          </template>
        </el-table-column>
        <el-table-column prop="total_amount"
                         label="总费用"
                         width="116"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="billType== 1">{{ scope.row.total_amount + "元" }}</span>
            <span v-if="billType== 2">{{ scope.row.total_in_amount + "元" }}</span>

            <span v-if="billType== 3">{{ scope.row.amount + "元" }}</span>
          </template>
        </el-table-column>
      </el-table>

      <div slot="footer"
           class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary"
                   @click="emitClose">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { serviceorderlist, paymentLog } from '../../../request/api'
import { crmUrl } from '@/utils/helper.js'
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      tableData: [],
      billType: null,
    }
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
    },
    content(val) {
      this.billType = val.source_order_type
      // 机构账单
      if (val.source_order_type == 1) {
        let query = {
          isPaging: 0,
          ids: val.bills,
        }
        serviceorderlist(query).then((res) => {
          this.tableData = res.data.data.list || []
        })
        // 共享订单
      } else if (val.source_order_type == 2) {
        let str = crmUrl()
        let data = {
          isPaging: 0,
          ref_company_invoice_order_id: val.id,
        }
        this.$axios({
          url: `${str}/api/v1/sharePlatform/findOrder/list`,
          method: 'get', //可以省略 不写默认是get
          params: data,
        }).then((response) => {
          this.tableData = response.data.data.list || []
        })
      } else {
        let str = crmUrl()
        let data = {
          isPaging: 0,
          cid: val.cid,
          ref_company_invoice_order_id: val.id,
        }
        this.$axios({
          url: `${str}/api/v1/company/account/orderSender/paymentLog`,
          method: 'get', //可以省略 不写默认是get
          params: data,
        }).then((response) => {
          this.tableData = response.data.data.list || []
        })
      }
    },
  },
  methods: {
    emitClose() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="less" scoped>
</style>