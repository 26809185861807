<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>财务</el-breadcrumb-item>
        <el-breadcrumb-item>开票审批</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 20px">
      <ScreenInvoicingRequisition @submit="scrrenTable"></ScreenInvoicingRequisition>
    </el-card>
    <el-card style="margin-top: 20px">
      <TableTittle :title="TableName"></TableTittle>
      <el-form label-position="right"
               :model="screenForm"
               :inline="true"
               size="small">
        <el-form-item style="float: right">
          <el-radio-group v-model="query.source_order_types"
                          @change="getList(query)">
            <el-radio-button :label="'1'">机构账单</el-radio-button>
            <el-radio-button :label="'2,3'">共享订单</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-table :data="tableData"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                border>
        <el-table-column prop="id"
                         label="ID"
                         width="50"></el-table-column>
        <el-table-column prop="type"
                         label="机构简称"
                         width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.company.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="source_order_type"
                         label="订单类型"
                         width="150">
          <template slot-scope="scope">
            <el-tag size="small"
                    type="success"
                    v-show="scope.row.source_order_type == 1">机构账单</el-tag>
            <el-tag size="small"
                    v-show="scope.row.source_order_type == 2">共享订单</el-tag>
            <el-tag size="small"
                    type="success"
                    v-show="scope.row.source_order_type == 3">充值订单</el-tag>
          </template>

        </el-table-column>
        <el-table-column prop="invoice_status"
                         label="发票状态"
                         width="150">
          <template slot-scope="scope">
            <el-tag size="small"
                    type=""
                    v-show="scope.row.invoice_status == 0">待处理</el-tag>
            <el-tag size="small"
                    type="warning"
                    v-show="scope.row.invoice_status == 1">待开票</el-tag>
            <el-tag size="small"
                    type="success"
                    v-show="scope.row.invoice_status == 2">开票成功</el-tag>
            <el-tag size="small"
                    type="danger"
                    v-show="scope.row.invoice_status == 3">开票失败</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="order_no"
                         label="关联账单"
                         width="220">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="openCheckBillsTableDialog(scope.row)"
                     :disabled="!$buttonPermission('invoicingrequisition:check')">账单列表</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="receiver_name"
                         label="开票人"
                         width="210px">
          <template slot-scope="scope">
            <span v-if="scope.row.source_order_type == 1">{{scope.row.receiver_name}}</span>
            <span v-else>{{scope.row.crm_creator?scope.row.crm_creator.alias :''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="invoice_amount"
                         label="开票金额">
          <template slot-scope="scope">
            <span v-if="scope.row.invoice_amount">{{ scope.row.invoice_amount + "元" }}
            </span>
            <span v-else>0元</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time"
                         width="120px"
                         label="申请日期">
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column prop="invoice_time"
                         width="180px"
                         label="开票日期">
          <template slot-scope="scope">
            <span v-if="scope.row.invoice_time">{{ scope.row.invoice_time | dateFormat("YYYY-MM-DD") }}
            </span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column prop="date"
                         label="操作"
                         width="150"
                         fixed="right">
          <template slot-scope="scope">
            <div class="link-template">
              <el-link type="primary"
                       :underline="false"
                       @click="openCheckInvoiceDialog(scope.row)"
                       :disabled="!$buttonPermission('invoicingrequisition:check')">查看</el-link>
              <el-popover placement="bottom"
                          width="150"
                          trigger="click"
                          v-if="scope.row.source_order_type == 1">
                <div class="popoverStyle">
                  <el-link type="primary"
                           :underline="false"
                           @click="openDoInvoiceDialog(scope.row, 1)">电子发票</el-link>
                  <el-link type="primary"
                           :underline="false"
                           @click="openDoInvoiceDialog(scope.row, 2)">纸质发票</el-link>
                </div>
                <el-link type="primary"
                         slot="reference"
                         :underline="false"
                         v-show="scope.row.invoice_status == 1 "
                         :disabled="
                    !$buttonPermission('invoicingrequisition:Invoicing')
                  ">开票</el-link>
              </el-popover>
              <el-link type="primary"
                       slot="reference"
                       :underline="false"
                       v-show="scope.row.invoice_status == 1&&scope.row.source_order_type == 2||scope.row.invoice_status == 1&&scope.row.source_order_type == 3"
                       @click="openDoInvoiceDialog(scope.row, 1)"
                       :disabled="
                    !$buttonPermission('invoicingrequisition:Invoicing')
                  ">开票</el-link>
              <el-link type="danger"
                       :underline="false"
                       @click="opendialogVisible(scope.row)"
                       v-show="scope.row.invoice_status == 1&&scope.row.source_order_type ==1||scope.row.invoice_status == 1&&scope.row.source_order_type ==2||scope.row.invoice_status == 1&&scope.row.source_order_type ==3"
                       :disabled="!$buttonPermission('invoicingrequisition:examine')">拒绝</el-link>
              <el-link type="success"
                       :underline="false"
                       @click="makeInvoice(scope.row)"
                       v-show="scope.row.invoice_status == 2">已开票</el-link>
              <el-link type="danger"
                       :underline="false"
                       v-show="scope.row.invoice_status == 3">已拒绝</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <CheckInvoice :isVisible="CheckInvoiceDialog"
                  :content="CheckInvoiceContent"
                  @close="CheckInvoiceClose()"></CheckInvoice>
    <DoInvoice :isVisible="DoInvoiceDialog"
               :content="DoInvoiceContent"
               @close="DoInvoiceDialog = false"
               :invoicetype="DoInvoiceType"
               @submit="DoInvoiceSubmit">
    </DoInvoice>
    <CheckBillsTable :content="CheckBillsTableContent"
                     :isVisible="CheckBillsTableDialog"
                     @close="CheckBillsTableDialog = false"></CheckBillsTable>
    <el-dialog top="5vh"
               title="拒绝理由"
               :visible="dialogVisible"
               width="500px">
      <el-form :model="form"
               ref="dialogForm"
               :rules="rules">
        <el-form-item prop="audit_feedback">
          <el-input type="textarea"
                    v-model="form.audit_feedback"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="submit('dialogForm')">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog top="5vh"
               title="开票信息"
               :visible.sync="dialogFormVisible"
               :close-on-click-modal="false"
               width="700px"
               @close="dialogFormVisible = false">

      <el-result icon="success"
                 title="开票成功">
        <template slot="extra">
          <div class="content-car">
            <span>当前发票为 {{detail.invoice_type==1?'纸质发票，已为您安排邮寄':'电子发票'}}</span>
            <span v-if="detail.invoice_type==1">快递名称：{{ detail.express_name }}&nbsp;&nbsp;&nbsp;快递单号：{{
              detail.express_no
            }}</span>
            <span v-else>{{detail.invoice_online_file}}</span>
          </div>
          <el-button type="primary"
                     size="medium"
                     v-if="detail.invoice_type==1"
                     @click="dialogFormVisible=false">返回</el-button>
          <el-button type="primary"
                     size="medium"
                     v-else
                     @click="open(detail.invoice_online_file)">点击查看</el-button>
        </template>
      </el-result>
      <!-- <div v-if="detail.invoice_type == 1">
         <el-descriptions :column="1">
          <el-descriptions-item label="快递名称"
            >{{detail.express_name}}</el-descriptions-item
          >
          <el-descriptions-item label="快递单号"
            >{{detail.express_no}}</el-descriptions-item
          >
        </el-descriptions>
      </div>
      <div v-else>
          <iframe :src="detail.invoice_online_file" frameborder="0" style="width:100%;height:500px"></iframe>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import CheckInvoice from '../../../components/DialogComponents/CheckInvoice/CheckInvoice.vue'
import DoInvoice from '../../../components/DialogComponents/DoInvoice/DoInvoice.vue'
import CheckBillsTable from '../../../components/DialogComponents/CheckBillsTable/CheckBillsTable.vue'
import ScreenInvoicingRequisition from '../../../components/ScreenComponents/ScreenInvoicingRequisition/ScreenInvoicingRequisition.vue'
import {
  applylist,
  companyinvoicedone,
  companyinvoiceapplyauditfail,
} from '../../../request/api'
export default {
  components: {
    TableTittle,
    CheckInvoice,
    DoInvoice,
    CheckBillsTable,
    ScreenInvoicingRequisition,
  },
  data() {
    return {
      dialogFormVisible: false,
      detail: {},
      TableName: '开票记录',
      tableData: [],
      screenForm: {},
      deleteList: [],
      CheckInvoiceDialog: false,
      CheckBillsTableDialog: false, // 查看账单表格弹窗
      CheckInvoiceContent: {},
      CheckBillsTableContent: {},
      DoInvoiceDialog: false,
      DoInvoiceContent: {},
      DoInvoiceType: 1,
      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        source_order_types: 1,
        invoice_status: [1, 2, 3].join(','),
      },
      dialogVisible: false, // 拒绝弹窗
      rules: {
        audit_feedback: [
          { required: true, message: '请输入拒绝理由', trigger: 'blur' },
        ],
      },
      form: {},
      rowInfo: {},
    }
  },
  mounted() {
    this.getList(this.query)
    this.getOptions()
  },
  methods: {
    open(row) {
      window.open(row)
    },
    makeInvoice(row) {
      this.detail = row
      this.dialogFormVisible = true
    },
    CheckInvoiceClose() {
      this.CheckInvoiceDialog = false
    },
    // 表格选中取值
    handleSelectionChange(val) {
      this.deleteList = val
    },
    getList(query) {
      applylist(query).then((res) => {
        this.tableData = res.data.data.list || []
        this.pageTotal = res.data.data.count
      })
    },
    getOptions() {
      this.$store.dispatch('getExpressOptions')
      this.$store.dispatch('getCompanyList')
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getList(this.query)
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.getList(this.query)
    },
    openCheckInvoiceDialog(item) {
      this.CheckInvoiceContent = item
      this.CheckInvoiceDialog = true
    },
    openDoInvoiceDialog(item, type) {
      this.DoInvoiceContent = item
      this.DoInvoiceType = type
      this.DoInvoiceDialog = !this.DoInvoiceDialog
    },
    // 下载文件
    downLoad(item) {
      this.$store.commit('downLoad', item.detail_file_url)
    },
    DoInvoiceSubmit({ form }) {
      let query = {}
      if (this.DoInvoiceType == 1) {
        query = {
          id: form.id,
          invoice_type: 2,
          invoice_online_file: form.agreement,
        }
      } else {
        query = {
          id: form.id,
          invoice_type: 1,
          express_code: form.express_code,
          express_no: form.express_no,
        }
      }

      companyinvoicedone(query).then((res) => {
        if (res.data.code == 0) {
          this.getList(this.query)
          this.DoInvoiceDialog = false
        }
      })
    },
    opendialogVisible(item) {
      this.rowInfo = item
      this.dialogVisible = true
    },
    openCheckBillsTableDialog(item) {
      this.CheckBillsTableContent = JSON.parse(JSON.stringify(item))
      this.CheckBillsTableDialog = !this.CheckBillsTableDialog
    },
    //筛选组件
    scrrenTable({ screenForm }) {
      this.$set(this.query, 'pageIndex', 1)
      this.query = {
        ...this.query,
        ...screenForm,
      }
      this.getList(this.query)
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            audit_feedback: this.form.audit_feedback,
            id: this.rowInfo.id,
          }
          companyinvoiceapplyauditfail(query).then((res) => {
            this.$nextTick(() => {
              this.dialogVisible = false
              this.getList(this.query)
            })
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.block {
  float: right;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.demo-form-inline {
  border-bottom: 2px dashed #d2d5df;
}
.popoverStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 92px;
}
.link-template {
  .el-link {
    margin: 0px 5px;
  }
}

.content-car {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 24px;
  span {
    display: block;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 20px;
    color: #909399;
    opacity: 1;
  }
}
.elimg {
  width: 53px;
  height: 53px;
}
</style>